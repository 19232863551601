import revive_payload_client_4sVQNw7RlN from "/codebuild/output/src2307955413/src/zeronet-web/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import components_plugin_KR1HBZs4kY from "/codebuild/output/src2307955413/src/zeronet-web/.nuxt/components.plugin.mjs";
import unhead_KgADcZ0jPj from "/codebuild/output/src2307955413/src/zeronet-web/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_jmwsqit4Rs from "/codebuild/output/src2307955413/src/zeronet-web/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import prefetch_client_5tzzN0oIVL from "/codebuild/output/src2307955413/src/zeronet-web/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_vue3_A0OWXRrUgq from "/codebuild/output/src2307955413/src/zeronet-web/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import chunk_reload_client_UciE0i6zes from "/codebuild/output/src2307955413/src/zeronet-web/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import api_GFfDXud5Cr from "/codebuild/output/src2307955413/src/zeronet-web/plugins/api.ts";
import auth0_client_xw5Ytmgnib from "/codebuild/output/src2307955413/src/zeronet-web/plugins/auth0.client.ts";
import gsap_client_YCLjR5XmPz from "/codebuild/output/src2307955413/src/zeronet-web/plugins/gsap.client.ts";
import gtm_client_OzmBuHLRIb from "/codebuild/output/src2307955413/src/zeronet-web/plugins/gtm.client.ts";
import observe_dH8EaOcBn8 from "/codebuild/output/src2307955413/src/zeronet-web/plugins/observe.ts";
import pinia_client_l2mvTNw9P0 from "/codebuild/output/src2307955413/src/zeronet-web/plugins/pinia.client.ts";
import pinia_48xmdi2HHl from "/codebuild/output/src2307955413/src/zeronet-web/plugins/pinia.ts";
import scroll_top_zHNrYl0aGO from "/codebuild/output/src2307955413/src/zeronet-web/plugins/scroll-top.ts";
export default [
  revive_payload_client_4sVQNw7RlN,
  components_plugin_KR1HBZs4kY,
  unhead_KgADcZ0jPj,
  router_jmwsqit4Rs,
  prefetch_client_5tzzN0oIVL,
  plugin_vue3_A0OWXRrUgq,
  chunk_reload_client_UciE0i6zes,
  api_GFfDXud5Cr,
  auth0_client_xw5Ytmgnib,
  gsap_client_YCLjR5XmPz,
  gtm_client_OzmBuHLRIb,
  observe_dH8EaOcBn8,
  pinia_client_l2mvTNw9P0,
  pinia_48xmdi2HHl,
  scroll_top_zHNrYl0aGO
]