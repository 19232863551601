import { default as _5_45month_45cart_45offereo1Uhz1VzYMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/5-month-cart-offer.vue?macro=true";
import { default as _6_45month_45half_45pricerVq00fHwpKMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/6-month-half-price.vue?macro=true";
import { default as aboutHhz6B8HhwMMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/about.vue?macro=true";
import { default as billingX4WK2lRy6TMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/account/billing.vue?macro=true";
import { default as indexTpMZUFA9BQMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/account/index.vue?macro=true";
import { default as profile9APQQOwYMpMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/account/profile.vue?macro=true";
import { default as accountCgJ5PzmsYUMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/account.vue?macro=true";
import { default as authorizesz4zwbvdQIMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/authorize.vue?macro=true";
import { default as bbc_45promoi28N4q0KhcMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/bbc-promo.vue?macro=true";
import { default as chatlfrJTqV7twMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/chat.vue?macro=true";
import { default as compass_45embedx9iLUJ0ZFrMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/compass-embed.vue?macro=true";
import { default as compassPgJe7ypTmdMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/compass.vue?macro=true";
import { default as contact1dzoJNV0aGMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/contact.vue?macro=true";
import { default as hyperfibreK9ayn2BJqXMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/hyperfibre.vue?macro=true";
import { default as indexLdBbyLLxd8Meta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/index.vue?macro=true";
import { default as internet_45speed_45testpMYs2qhjXcMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/internet-speed-test.vue?macro=true";
import { default as loginceBk3YhwgbMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/login.vue?macro=true";
import { default as logouttfSir0U6iKMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/logout.vue?macro=true";
import { default as our_45plans8Xaj79s3FLMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/our-plans.vue?macro=true";
import { default as plansirja70kIiGMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/plans.vue?macro=true";
import { default as privacyB9g6ykMbRHMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/privacy.vue?macro=true";
import { default as promotionsT0H8LL15uFMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/promotions.vue?macro=true";
import { default as _1tNDqxvAEjGMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/register/1.vue?macro=true";
import { default as _2myFipHhDe4Meta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/register/2.vue?macro=true";
import { default as _3gKLd91UdeoMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/register/3.vue?macro=true";
import { default as _4pQC3wPw2A5Meta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/register/4.vue?macro=true";
import { default as _55NPq5hWBoRMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/register/5.vue?macro=true";
import { default as _6EKUlaLuyA8Meta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/register/6.vue?macro=true";
import { default as index5ima87lc5qMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/register/index.vue?macro=true";
import { default as successrdcVDPxyeZMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/register/success.vue?macro=true";
import { default as registerGRO99XudefMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/register.vue?macro=true";
import { default as simply_45broadbandl4tcb5ZbaGMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/simply-broadband.vue?macro=true";
import { default as studentcard_45exclusive_45offerCVrcsBoOwaMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/studentcard-exclusive-offer.vue?macro=true";
import { default as sustainabilityQpjbwS9DfhMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/sustainability.vue?macro=true";
import { default as termsbmaEB4oZ7gMeta } from "/codebuild/output/src2307955413/src/zeronet-web/pages/terms.vue?macro=true";
export default [
  {
    name: _5_45month_45cart_45offereo1Uhz1VzYMeta?.name ?? "5-month-cart-offer",
    path: _5_45month_45cart_45offereo1Uhz1VzYMeta?.path ?? "/5-month-cart-offer",
    meta: _5_45month_45cart_45offereo1Uhz1VzYMeta || {},
    alias: _5_45month_45cart_45offereo1Uhz1VzYMeta?.alias || [],
    redirect: _5_45month_45cart_45offereo1Uhz1VzYMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/5-month-cart-offer.vue").then(m => m.default || m)
  },
  {
    name: _6_45month_45half_45pricerVq00fHwpKMeta?.name ?? "6-month-half-price",
    path: _6_45month_45half_45pricerVq00fHwpKMeta?.path ?? "/6-month-half-price",
    meta: _6_45month_45half_45pricerVq00fHwpKMeta || {},
    alias: _6_45month_45half_45pricerVq00fHwpKMeta?.alias || [],
    redirect: _6_45month_45half_45pricerVq00fHwpKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/6-month-half-price.vue").then(m => m.default || m)
  },
  {
    name: aboutHhz6B8HhwMMeta?.name ?? "about",
    path: aboutHhz6B8HhwMMeta?.path ?? "/about",
    meta: aboutHhz6B8HhwMMeta || {},
    alias: aboutHhz6B8HhwMMeta?.alias || [],
    redirect: aboutHhz6B8HhwMMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/about.vue").then(m => m.default || m)
  },
  {
    path: accountCgJ5PzmsYUMeta?.path ?? "/account",
    children: [
  {
    name: billingX4WK2lRy6TMeta?.name ?? "account-billing",
    path: billingX4WK2lRy6TMeta?.path ?? "billing",
    meta: billingX4WK2lRy6TMeta || {},
    alias: billingX4WK2lRy6TMeta?.alias || [],
    redirect: billingX4WK2lRy6TMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/account/billing.vue").then(m => m.default || m)
  },
  {
    name: indexTpMZUFA9BQMeta?.name ?? "account",
    path: indexTpMZUFA9BQMeta?.path ?? "",
    meta: indexTpMZUFA9BQMeta || {},
    alias: indexTpMZUFA9BQMeta?.alias || [],
    redirect: indexTpMZUFA9BQMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: profile9APQQOwYMpMeta?.name ?? "account-profile",
    path: profile9APQQOwYMpMeta?.path ?? "profile",
    meta: profile9APQQOwYMpMeta || {},
    alias: profile9APQQOwYMpMeta?.alias || [],
    redirect: profile9APQQOwYMpMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/account/profile.vue").then(m => m.default || m)
  }
],
    name: accountCgJ5PzmsYUMeta?.name ?? undefined,
    meta: accountCgJ5PzmsYUMeta || {},
    alias: accountCgJ5PzmsYUMeta?.alias || [],
    redirect: accountCgJ5PzmsYUMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/account.vue").then(m => m.default || m)
  },
  {
    name: authorizesz4zwbvdQIMeta?.name ?? "authorize",
    path: authorizesz4zwbvdQIMeta?.path ?? "/authorize",
    meta: authorizesz4zwbvdQIMeta || {},
    alias: authorizesz4zwbvdQIMeta?.alias || [],
    redirect: authorizesz4zwbvdQIMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/authorize.vue").then(m => m.default || m)
  },
  {
    name: bbc_45promoi28N4q0KhcMeta?.name ?? "bbc-promo",
    path: bbc_45promoi28N4q0KhcMeta?.path ?? "/bbc-promo",
    meta: bbc_45promoi28N4q0KhcMeta || {},
    alias: bbc_45promoi28N4q0KhcMeta?.alias || [],
    redirect: bbc_45promoi28N4q0KhcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/bbc-promo.vue").then(m => m.default || m)
  },
  {
    name: chatlfrJTqV7twMeta?.name ?? "chat",
    path: chatlfrJTqV7twMeta?.path ?? "/chat",
    meta: chatlfrJTqV7twMeta || {},
    alias: chatlfrJTqV7twMeta?.alias || [],
    redirect: chatlfrJTqV7twMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/chat.vue").then(m => m.default || m)
  },
  {
    name: compass_45embedx9iLUJ0ZFrMeta?.name ?? "compass-embed",
    path: compass_45embedx9iLUJ0ZFrMeta?.path ?? "/compass-embed",
    meta: compass_45embedx9iLUJ0ZFrMeta || {},
    alias: compass_45embedx9iLUJ0ZFrMeta?.alias || [],
    redirect: compass_45embedx9iLUJ0ZFrMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/compass-embed.vue").then(m => m.default || m)
  },
  {
    name: compassPgJe7ypTmdMeta?.name ?? "compass",
    path: compassPgJe7ypTmdMeta?.path ?? "/compass",
    meta: compassPgJe7ypTmdMeta || {},
    alias: compassPgJe7ypTmdMeta?.alias || [],
    redirect: compassPgJe7ypTmdMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/compass.vue").then(m => m.default || m)
  },
  {
    name: contact1dzoJNV0aGMeta?.name ?? "contact",
    path: contact1dzoJNV0aGMeta?.path ?? "/contact",
    meta: contact1dzoJNV0aGMeta || {},
    alias: contact1dzoJNV0aGMeta?.alias || [],
    redirect: contact1dzoJNV0aGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/contact.vue").then(m => m.default || m)
  },
  {
    name: hyperfibreK9ayn2BJqXMeta?.name ?? "hyperfibre",
    path: hyperfibreK9ayn2BJqXMeta?.path ?? "/hyperfibre",
    meta: hyperfibreK9ayn2BJqXMeta || {},
    alias: hyperfibreK9ayn2BJqXMeta?.alias || [],
    redirect: hyperfibreK9ayn2BJqXMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/hyperfibre.vue").then(m => m.default || m)
  },
  {
    name: indexLdBbyLLxd8Meta?.name ?? "index",
    path: indexLdBbyLLxd8Meta?.path ?? "/",
    meta: indexLdBbyLLxd8Meta || {},
    alias: indexLdBbyLLxd8Meta?.alias || [],
    redirect: indexLdBbyLLxd8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/index.vue").then(m => m.default || m)
  },
  {
    name: internet_45speed_45testpMYs2qhjXcMeta?.name ?? "internet-speed-test",
    path: internet_45speed_45testpMYs2qhjXcMeta?.path ?? "/internet-speed-test",
    meta: internet_45speed_45testpMYs2qhjXcMeta || {},
    alias: internet_45speed_45testpMYs2qhjXcMeta?.alias || [],
    redirect: internet_45speed_45testpMYs2qhjXcMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/internet-speed-test.vue").then(m => m.default || m)
  },
  {
    name: loginceBk3YhwgbMeta?.name ?? "login",
    path: loginceBk3YhwgbMeta?.path ?? "/login",
    meta: loginceBk3YhwgbMeta || {},
    alias: loginceBk3YhwgbMeta?.alias || [],
    redirect: loginceBk3YhwgbMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/login.vue").then(m => m.default || m)
  },
  {
    name: logouttfSir0U6iKMeta?.name ?? "logout",
    path: logouttfSir0U6iKMeta?.path ?? "/logout",
    meta: logouttfSir0U6iKMeta || {},
    alias: logouttfSir0U6iKMeta?.alias || [],
    redirect: logouttfSir0U6iKMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/logout.vue").then(m => m.default || m)
  },
  {
    name: our_45plans8Xaj79s3FLMeta?.name ?? "our-plans",
    path: our_45plans8Xaj79s3FLMeta?.path ?? "/our-plans",
    meta: our_45plans8Xaj79s3FLMeta || {},
    alias: our_45plans8Xaj79s3FLMeta?.alias || [],
    redirect: our_45plans8Xaj79s3FLMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/our-plans.vue").then(m => m.default || m)
  },
  {
    name: plansirja70kIiGMeta?.name ?? "plans",
    path: plansirja70kIiGMeta?.path ?? "/plans",
    meta: plansirja70kIiGMeta || {},
    alias: plansirja70kIiGMeta?.alias || [],
    redirect: plansirja70kIiGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/plans.vue").then(m => m.default || m)
  },
  {
    name: privacyB9g6ykMbRHMeta?.name ?? "privacy",
    path: privacyB9g6ykMbRHMeta?.path ?? "/privacy",
    meta: privacyB9g6ykMbRHMeta || {},
    alias: privacyB9g6ykMbRHMeta?.alias || [],
    redirect: privacyB9g6ykMbRHMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/privacy.vue").then(m => m.default || m)
  },
  {
    name: promotionsT0H8LL15uFMeta?.name ?? "promotions",
    path: promotionsT0H8LL15uFMeta?.path ?? "/promotions",
    meta: promotionsT0H8LL15uFMeta || {},
    alias: promotionsT0H8LL15uFMeta?.alias || [],
    redirect: promotionsT0H8LL15uFMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/promotions.vue").then(m => m.default || m)
  },
  {
    path: registerGRO99XudefMeta?.path ?? "/register",
    children: [
  {
    name: _1tNDqxvAEjGMeta?.name ?? "register-1",
    path: _1tNDqxvAEjGMeta?.path ?? "1",
    meta: _1tNDqxvAEjGMeta || {},
    alias: _1tNDqxvAEjGMeta?.alias || [],
    redirect: _1tNDqxvAEjGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/register/1.vue").then(m => m.default || m)
  },
  {
    name: _2myFipHhDe4Meta?.name ?? "register-2",
    path: _2myFipHhDe4Meta?.path ?? "2",
    meta: _2myFipHhDe4Meta || {},
    alias: _2myFipHhDe4Meta?.alias || [],
    redirect: _2myFipHhDe4Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/register/2.vue").then(m => m.default || m)
  },
  {
    name: _3gKLd91UdeoMeta?.name ?? "register-3",
    path: _3gKLd91UdeoMeta?.path ?? "3",
    meta: _3gKLd91UdeoMeta || {},
    alias: _3gKLd91UdeoMeta?.alias || [],
    redirect: _3gKLd91UdeoMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/register/3.vue").then(m => m.default || m)
  },
  {
    name: _4pQC3wPw2A5Meta?.name ?? "register-4",
    path: _4pQC3wPw2A5Meta?.path ?? "4",
    meta: _4pQC3wPw2A5Meta || {},
    alias: _4pQC3wPw2A5Meta?.alias || [],
    redirect: _4pQC3wPw2A5Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/register/4.vue").then(m => m.default || m)
  },
  {
    name: _55NPq5hWBoRMeta?.name ?? "register-5",
    path: _55NPq5hWBoRMeta?.path ?? "5",
    meta: _55NPq5hWBoRMeta || {},
    alias: _55NPq5hWBoRMeta?.alias || [],
    redirect: _55NPq5hWBoRMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/register/5.vue").then(m => m.default || m)
  },
  {
    name: _6EKUlaLuyA8Meta?.name ?? "register-6",
    path: _6EKUlaLuyA8Meta?.path ?? "6",
    meta: _6EKUlaLuyA8Meta || {},
    alias: _6EKUlaLuyA8Meta?.alias || [],
    redirect: _6EKUlaLuyA8Meta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/register/6.vue").then(m => m.default || m)
  },
  {
    name: index5ima87lc5qMeta?.name ?? "register",
    path: index5ima87lc5qMeta?.path ?? "",
    meta: index5ima87lc5qMeta || {},
    alias: index5ima87lc5qMeta?.alias || [],
    redirect: index5ima87lc5qMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/register/index.vue").then(m => m.default || m)
  },
  {
    name: successrdcVDPxyeZMeta?.name ?? "register-success",
    path: successrdcVDPxyeZMeta?.path ?? "success",
    meta: successrdcVDPxyeZMeta || {},
    alias: successrdcVDPxyeZMeta?.alias || [],
    redirect: successrdcVDPxyeZMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/register/success.vue").then(m => m.default || m)
  }
],
    name: registerGRO99XudefMeta?.name ?? undefined,
    meta: registerGRO99XudefMeta || {},
    alias: registerGRO99XudefMeta?.alias || [],
    redirect: registerGRO99XudefMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/register.vue").then(m => m.default || m)
  },
  {
    name: simply_45broadbandl4tcb5ZbaGMeta?.name ?? "simply-broadband",
    path: simply_45broadbandl4tcb5ZbaGMeta?.path ?? "/simply-broadband",
    meta: simply_45broadbandl4tcb5ZbaGMeta || {},
    alias: simply_45broadbandl4tcb5ZbaGMeta?.alias || [],
    redirect: simply_45broadbandl4tcb5ZbaGMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/simply-broadband.vue").then(m => m.default || m)
  },
  {
    name: studentcard_45exclusive_45offerCVrcsBoOwaMeta?.name ?? "studentcard-exclusive-offer",
    path: studentcard_45exclusive_45offerCVrcsBoOwaMeta?.path ?? "/studentcard-exclusive-offer",
    meta: studentcard_45exclusive_45offerCVrcsBoOwaMeta || {},
    alias: studentcard_45exclusive_45offerCVrcsBoOwaMeta?.alias || [],
    redirect: studentcard_45exclusive_45offerCVrcsBoOwaMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/studentcard-exclusive-offer.vue").then(m => m.default || m)
  },
  {
    name: sustainabilityQpjbwS9DfhMeta?.name ?? "sustainability",
    path: sustainabilityQpjbwS9DfhMeta?.path ?? "/sustainability",
    meta: sustainabilityQpjbwS9DfhMeta || {},
    alias: sustainabilityQpjbwS9DfhMeta?.alias || [],
    redirect: sustainabilityQpjbwS9DfhMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/sustainability.vue").then(m => m.default || m)
  },
  {
    name: termsbmaEB4oZ7gMeta?.name ?? "terms",
    path: termsbmaEB4oZ7gMeta?.path ?? "/terms",
    meta: termsbmaEB4oZ7gMeta || {},
    alias: termsbmaEB4oZ7gMeta?.alias || [],
    redirect: termsbmaEB4oZ7gMeta?.redirect || undefined,
    component: () => import("/codebuild/output/src2307955413/src/zeronet-web/pages/terms.vue").then(m => m.default || m)
  }
]